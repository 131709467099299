@use '../base/mixins' as *;

.deco {
  position: absolute;
  z-index: -90;
  opacity: 0.3;

  &__cross {
    opacity: 0;

    @include breakpoint-up(medium) {
      top: -6rem;
      left: 50%;
      transform: translateX(-47%) scale(0.8);
      opacity: 0.3;
    }
  }

  &__spoon--hero {
    top: 112rem;
    left: 9rem;
    opacity: 1;
    stroke: var(--decoration-color);

    @include breakpoint-up(xsmall) {
      top: 35%;
      left: 15%;
    }

    @include breakpoint-up(small) {
      top: 38%;
    }
    @include breakpoint-up(medium) {
      top: 73rem;
      left: 50%;
    }
  }

  &__spoon--skills {
    opacity: 0;
    stroke: var(--decoration-color);

    @include breakpoint-up(medium) {
      bottom: 60rem;
      right: 50%;
      opacity: 1;
      transform: scale(-1);
    }
  }

  &__notch--hero {
    opacity: 0;
    top: 21%;
    right: 15%;
    transform: translateX(50%);

    @include breakpoint-up(medium) {
      opacity: 0.3;
    }
  }

  &__notch--skills {
    top: 226rem;
    left: 7.8rem;

    @include breakpoint-up(xsmall) {
      top: 229rem;
      left: 15%;
      transform: translateX(-50%);
    }

    @include breakpoint-up(small) {
      top: 71%;
    }

    @include breakpoint-up(medium) {
      top: 170rem;
    }

    @include breakpoint-up(large) {
      top: 58%;
    }
  }

  &__diamond {
    bottom: 32rem;
    left: 7rem;

    @include breakpoint-up(xsmall) {
      bottom: 31rem;
      left: 15%;
      transform: translateX(-50%);
    }

    @include breakpoint-up(small) {
      bottom: 10%;
    }

    @include breakpoint-up(medium) {
      bottom: 12%;
    }
  }

  &__diamond-notch {
    bottom: 34rem;
    left: 7.8rem;

    @include breakpoint-up(xsmall) {
      bottom: 33rem;
      left: 15%;
      transform: translateX(-50%);
    }

    @include breakpoint-up(small) {
      bottom: 10.65%;
    }

    @include breakpoint-up(medium) {
      bottom: 12.7%;
    }
  }
}

.decorations {
  height: 100%;
  width: 100%;

  &__col {
    position: fixed;
    z-index: -999;
    background-color: var(--decoration-color);
    height: 100%;
    width: 2px;
    top: 0;
    left: 9rem;

    @include breakpoint-up(xsmall) {
      left: 15%;
    }

    &::before {
      content: "";
      position: fixed;
      z-index: -999;
      background-color: var(--decoration-color);
      height: 100%;
      width: 2px;
      top: 0;
      left: 50%;
      display: none;

      @include breakpoint-up(medium) {
        display: initial;
      }
    }

    &::after {
      content: "";
      position: fixed;
      z-index: -999;
      background-color: var(--decoration-color);
      height: 100%;
      width: 2px;
      top: 0;
      right: 15%;
      display: none;

      @include breakpoint-up(small) {
        display: initial;
      }
    }
  }
}
