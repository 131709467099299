@use '../base/mixins' as *;

:root {
  // Colors
  --bg-color: hsl(210, 39%, 16%);
  --preloader-bg-color: hsl(208, 45%, 14.85%);
  --container-bg-color: hsl(188, 50%, 20%);
  --decoration-color: hsl(209, 39%, 19%);
  --primary-color: hsl(168, 100%, 50%);
  --text-color: hsl(38, 22%, 90%);
  --text-darker-color: hsla(38, 22%, 90%, 0.8);
  --selection-color: hsl(336, 80%, 50%);
  --scrollbar-hover-color: hsl(168, 100%, 30%);

  // Fallback fonts
  --fallback-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Robot",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  // Transitions and Animations
  --basic-timing: ease-in-out;
  --duration-short: 250ms;
  --duration-medium: 400ms;

  font-size: 10px;
}

body {
  background-color: var(--bg-color);
  position: relative;
  overflow-x: hidden;

  &::before {
    content: "";
    background-image: url("/assets/background.gif");

    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.03;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    will-change: auto;
    pointer-events: none;

    @media (prefers-reduced-motion) {
      background-image: none;
    }
  }
}

// Hiding content on viewports smaller than tablet
.hide-mobile {
  display: none !important;
  @include breakpoint-up(medium) {
    display: initial !important;
  }
}

.hide-desktop {
  @include breakpoint-up(large) {
    display: none !important;
  }
}

// Showing content on viewports larger than tablet
.show-desktop {
  display: none !important;
  @include breakpoint-up(large) {
    display: initial !important;
  }
}

// Spacing
.side-spacer {
  padding: 0 3rem;

  @include breakpoint-up(xsmall) {
    padding: 0 6rem;
  }

  @include breakpoint-up(medium) {
    padding: 0 3rem;
  }
}

.element-spacer {
  margin-bottom: 6rem;

  @include breakpoint-up(medium) {
    margin-bottom: 8rem;
  }

  @include breakpoint-up(large) {
    margin-bottom: 10rem;
  }
}

.headline-spacer {
  margin-bottom: 0.25rem;

  @include breakpoint-up(medium) {
    margin-bottom: 0.5rem;
  }
}

// Sizing

.section-container {
  margin: 8rem auto;
  max-width: min(640px, 100%);

  @include breakpoint-up(medium) {
    margin-bottom: 16rem;
    max-width: min(900px, 100%);
  }

  @include breakpoint-up(large) {
    margin-bottom: 20rem;
    max-width: min(1000px, 100%);
  }

  @include breakpoint-up(xlarge) {
    margin-bottom: 24rem;
    max-width: min(1100px, 100%);
  }

  @include breakpoint-up(xxlarge) {
    margin-bottom: 24rem;
    max-width: min(1300px, 100%);
  }
}
