@use '../base/mixins' as *;

@font-face {
  font-family: "Michroma";
  src: url("/fonts/Michroma.woff2") format("woff2");

  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-Regular.woff2") format("woff2");

  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-Medium.woff2") format("woff2");

  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-SemiBold.woff2") format("woff2");

  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-ExtraBold.woff2") format("woff2");

  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-Black.woff2") format("woff2");

  font-weight: 900;
  font-display: swap;
}
body {
  font-family: "Kanit", var(--fallback-font);
  font-size: 1.6em;
  font-weight: 400;
  color: var(--text-color);
  letter-spacing: 0.1rem;

  @include breakpoint-up(medium) {
    font-size: 1.8rem;
  }
}
h1,
h2,
h3,
h4,
a {
  line-height: 1.3;
  color: var(--primary-color);
}

h1 {
  font-family: "Michroma", var(--fallback-font);
  font-weight: 400;
}
h2 {
  font-family: "Kanit", var(--fallback-font);
  font-weight: 800;
  font-size: 3rem;

  @include breakpoint-up(medium) {
    font-size: 3.4rem;
  }
}
h3 {
  font-family: "Michroma", var(--fallback-font);
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 0.1rem;

  @include breakpoint-up(medium) {
    font-size: 2.4rem;
  }
}
.subheadline--italic {
  font-family: "Kanit", var(--fallback-font);
  font-style: italic;
  letter-spacing: 0;
  text-transform: lowercase;
}

::selection {
  background-color: var(--selection-color);
  color: var(--text-color);
}
