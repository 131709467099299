@use '../base/mixins' as *;

.skills__container {
  background-color: var(--container-bg-color);
  display: flex;
  flex-direction: column;
  position: relative;
  place-self: center;
  transform-origin: center center;

  &::before {
    content: "";
    position: absolute;
    background-color: var(--primary-color);
    top: -3px;
    left: -3.5px;
    height: 2rem;
    width: 2rem;
    z-index: -1;
  }

  &--proficient {
    &::before {
      box-shadow: 0 206px 0 var(--primary-color), 282px 0 var(--primary-color),
        282px 206px 0 var(--primary-color);

      @include breakpoint-up(medium) {
        box-shadow: 0 217px 0 var(--primary-color), 303px 0 var(--primary-color),
          303px 217px 0 var(--primary-color);
      }

      @include breakpoint-up(large) {
        box-shadow: 0 257px 0 var(--primary-color), 362px 0 var(--primary-color),
          362px 257px 0 var(--primary-color);
      }
    }
  }

  &--learning {
    &::before {
      top: -3.25px;
      box-shadow: 0 134px 0 var(--primary-color), 282px 0 var(--primary-color),
        282px 134px 0 var(--primary-color);

      @include breakpoint-up(medium) {
        box-shadow: 0 142px 0 var(--primary-color), 302px 0 var(--primary-color),
          302px 142px 0 var(--primary-color);
      }

      @include breakpoint-up(large) {
        box-shadow: 0 182px 0 var(--primary-color), 362px 0 var(--primary-color),
          362px 182px 0 var(--primary-color);
      }
    }
    .skills__container__headline {
      &::after {
        left: 129px;

        @include breakpoint-up(medium) {
          left: 144px;
        }
      }
    }
  }
}
