@use '../base/mixins' as *;

.menu {
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 900;
    pointer-events: none;

    &--inner.modal--open,
    &--outer.modal--open {
      pointer-events: initial;
    }
  }
  &__modal--outer {
    background: hsla(210, 39%, 16%, 0.8);
    opacity: 0;
  }
  &__modal--inner {
    background-color: var(--container-bg-color);
    z-index: 900;
    opacity: 0;
    pointer-events: none;
    transition: all var(--duration-short) var(--basic-timing);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint-up(large) {
      top: 50%;
      left: 50%;
      height: 550px;
      width: 850px;
      transform: translate3d(-50%, -50%, 0);
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }

    @include breakpoint-up(xlarge) {
      max-height: 600px;
      max-width: 900px;
    }
  }

  &__socials {
    order: 2;
    margin-top: 4rem;

    @include breakpoint-up(large) {
      max-width: 200px;
      order: 1;
      display: flex;
      margin-top: revert;
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;

      @include breakpoint-up(xsmall) {
        justify-content: initial;
      }

      @include breakpoint-up(medium) {
        max-width: 500px;
        margin: 0 auto;
        padding: 0 0 0 1rem;
        justify-content: start;
      }

      @include breakpoint-up(large) {
        // max-width: 200px;
        padding: 0;
        flex-direction: column;
        gap: 6rem;
      }
    }

    &__items svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    &__items a {
      text-decoration: none;
    }

    &__items:not(:last-of-type) {
      font-size: 0;
      width: min-content;
    }
    &__items:last-of-type a {
      font-family: "Kanit", var(--fallback-font);
      font-weight: 700;
      font-size: 3rem;
      display: inline-block;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-color: var(--primary-color);
      -webkit-text-stroke-width: 1px;
      letter-spacing: 1.75px;
      text-transform: lowercase;
      padding-top: 0.1rem;

      @include breakpoint-up(medium) {
        padding-top: 0;
        -webkit-text-stroke-width: 0.75px;

        @-moz-document url-prefix() {
          -webkit-text-stroke-width: 0.8px;
        }
      }
    }
  }

  &__form {
    order: 1;

    @include breakpoint-up(large) {
      order: 2;
    }
  }
}

body.prevent-scroll {
  padding-right: 0.4rem;
  overflow: hidden;

  @-moz-document url-prefix() {
    padding-right: 0.75rem;
  }
}
