@use '../base/mixins' as *;

.footer {
  margin-bottom: 0;

  & > * {
    margin-bottom: 3rem;
  }

  &__headline {
    line-height: 1;
    letter-spacing: 0.1rem;
    position: relative;

    &--subheadline {
      font-size: 2rem;
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--text-color);
    }
  }
  &__info {
    max-width: 450px;
  }

  &__socials {
    &__list {
      list-style: none;
      height: 3.5rem;
      align-items: center;
      justify-content: start;
      display: flex;
      gap: 3rem;
      padding: 0;
      margin: 0;
    }

    &__items {
      font-size: 0;
      letter-spacing: 0;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  &__copyright {
    font-family: "Kanit", var(--fallback-font);
    font-size: 1.4rem;
    margin-bottom: 2rem;

    @include breakpoint-up(medium) {
      font-size: 1.6rem;
      margin-bottom: 4rem;
    }
  }
}
// GSAP reveal animation
.footer-reveal {
  display: block;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: 0 0;
    background: #192939;
  }
}
