@use '../base/mixins' as *;

.projects {
  &__container {
    display: grid;
    grid-template-columns: 1fr 3rem;
    grid-template-rows: auto;
    grid-template-areas:
      "card card"
      "title icon"
      "info ."
      "link .";

    @include breakpoint-up(medium) {
      // max-width: 450px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "card title icon"
        "card info ."
        "card . ."
        "card link .";
      grid-gap: 0 1em;
    }

    @include breakpoint-up(large) {
      grid-template-areas:
        "card title"
        "card info"
        "card link"
        "card .";
      grid-gap: 0 3em;
    }
  }

  &__card {
    grid-area: card;
    position: relative;

    a {
      display: grid;
      place-items: center;
      max-width: 90%;
    }

    &__screenshot {
      source,
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__title {
    grid-area: title;

    @include breakpoint-up(medium) {
      grid-column: initial;
    }
  }
  &__links {
    grid-area: icon;
    margin: 0 0 0 0.5rem;

    @include breakpoint-up(medium) {
      align-self: end;
    }

    @include breakpoint-up(large) {
      grid-area: link;
      grid-row: span 2;
      margin: 0 0 1rem 0.5rem;
    }

    & .project__button:nth-of-type(2) {
      margin-left: 4rem;
    }
  }

  &__link {
    grid-area: icon;
    font-size: 0;
    letter-spacing: 0;

    @include breakpoint-up(medium) {
      grid-area: link;
      align-self: end;
    }

    @include breakpoint-up(large) {
      font-size: initial;
      letter-spacing: initial;
      text-decoration: none;
    }

    svg {
      width: 2.5rem;
      height: 3rem;
    }

    &--mobile {
      grid-area: link;
      text-decoration: none;
      margin-top: 1rem;
      display: flex;
      align-self: flex-end;
      gap: 1rem;

      svg {
        align-self: center;
      }
    }
  }

  &__info {
    grid-area: info;
    margin-top: 0.75rem;
  }
}

// GSAP reveal animation
.project-reveal-em {
  display: block;
  position: relative;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    display: block;
    left: 0;
    width: 150%;
    height: 110%;
    z-index: 1;
    transform-origin: 100% 0;
    background: #192939;
  }
}
.project-reveal-hch {
  display: block;
  position: relative;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    display: block;
    left: 0;
    width: 110%;
    height: 110%;
    z-index: 1;
    transform-origin: 100% 0;
    background: #192939;
  }
}
