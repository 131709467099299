@use '../base/mixins' as *;

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: var(--preloader-bg-color);
  display: grid;
  place-items: center;
  pointer-events: none;
  overflow: hidden;

  &::before {
    content: "''";
    background-image: url("/assets/background.gif");
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.0255;
    top: 0;
    left: 0;
    z-index: 999;
  }

  &__img {
    @include breakpoint-up(medium) {
      width: 500px;
    }
  }
}
