@use '../base/mixins' as *;

.hero {
  display: flex;
  flex-direction: column;

  @include breakpoint-up(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "headline image"
      "info image"
      "info2 image"
      "info3 image"
      "info4 image"
      "button image";
    grid-gap: 1em 3em;
    margin-top: 10rem;
  }

  @include breakpoint-up(large) {
    grid-gap: 1em 7em;
    margin-top: 16rem;
  }

  &__picture__container {
    grid-area: image;
    margin-bottom: 4rem;

    @include breakpoint-up(medium) {
      margin-bottom: 4rem;
      place-self: center;
    }

    @include breakpoint-up(large) {
      margin-bottom: initial;
    }
  }

  &__picture {
    object-fit: cover;
  }

  &__headline {
    line-height: 1;
    grid-area: headline;
    position: relative;

    &--headline {
      margin: 0.25rem 0 0 -0.4rem;
      font-size: 5rem;

      &:last-of-type {
        margin: 0 0 2rem -0.4rem;

        @include breakpoint-up(medium) {
          margin-bottom: 0;
        }
      }
    }

    &--subheadline {
      font-size: 1.8rem;
      font-family: "Kanit", var(--fallback-font);
      font-weight: 800;
      letter-spacing: 0.2rem;
      color: var(--text-color);
      text-transform: uppercase;
    }
  }

  &__introduction {
    &:nth-of-type(1) {
      grid-area: info;

      @include breakpoint-up(medium) {
        margin-top: 1rem;
      }
    }
    &:nth-of-type(2) {
      grid-area: info2;
    }
    &:nth-of-type(3) {
      grid-area: info3;
    }
    &:nth-of-type(4) {
      grid-area: info4;
    }

    &:not(:last-of-type) {
      margin-bottom: 2rem;

      @include breakpoint-up(medium) {
        margin-bottom: 0;
      }
    }
  }
  &__button {
    grid-area: button;
    width: max-content;

    button {
      margin: 5rem 0 0 2rem;

      @include breakpoint-up(medium) {
        margin: 2rem 0 0 2rem;
      }

      @include breakpoint-up(xlarge) {
        margin: 2rem 0 -0.5rem 2rem;
      }
    }
  }
}
// GSAP reveal animation
.headline-reveal {
  display: block;

  &:after {
    content: " ";
    position: absolute;
    left: -0.5rem;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: 0 0;
    background: #192939;
  }
}
