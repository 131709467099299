@use '../base/mixins' as *;

.menu__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint-up(medium) {
    align-self: center;
  }
}

.form__headline {
  font-size: 4.5rem;
  margin-bottom: -1rem;
}

.form__headline--subheadline {
  font-size: 1.6rem;
  margin-bottom: 4rem;

  @include breakpoint-up(medium) {
    font-size: 1.8rem;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 100%;
}

.contact__form {
  &__button {
    margin: 2rem 1rem 3rem;

    .cta::before {
      background-color: var(--bg-color);
    }
    .cta:hover + .corners,
    .cta:hover + .corners + .corners {
      border-color: var(--bg-color);
    }
  }
  &__corners {
    &.top-block {
      top: -8px;
      left: 86px;

      &::before {
        right: -5px;
      }
    }

    &.bottom-block {
      left: -8px;
      bottom: -8px;
    }
  }
  &__corners,
  &__corners::before {
    background-color: var(--container-bg-color);
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    background-color: var(--container-bg-color);
    appearance: none;
    border: none;
    font-size: 2rem;
    color: var(--text-darker-color);

    &:focus {
      outline: 0;
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: var(--text-color);
    opacity: 1;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
    transition: color var(--duration-medium) var(--basic-timing);
  }

  input:focus-within,
  input:focus-within::placeholder,
  textarea:focus-within,
  textarea:focus-within::placeholder {
    color: var(--primary-color);
  }

  &__border {
    position: relative;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    margin-top: -2rem;
    background-color: var(--text-color);
    transition: all var(--duration-medium) var(--basic-timing);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      height: 100%;
      background-color: var(--primary-color);
      transition: all var(--duration-medium) var(--basic-timing);
    }
  }

  input:focus-within + .contact__form__border::before,
  textarea:focus-within + .contact__form__border::before {
    width: 100%;
  }
}
