@use '../base/mixins' as *;

.skills {
  display: grid;

  &__container {
    padding: 2rem 0 2rem 2rem;

    @include breakpoint-up(medium) {
      padding: 2rem 1rem 2rem 3rem;
      &--proficient {
        margin-right: auto;
      }
      &--learning {
        margin-left: auto;
      }
    }

    @include breakpoint-up(large) {
      padding: 4rem 4rem 4rem 6rem;
    }

    &__headline {
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 1.5rem;

      @include breakpoint-up(medium) {
        font-size: 1.8rem;
      }

      &::after {
        content: "_";
        position: absolute;
        top: -1px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-right: 2.5rem;
    padding: 0;
    align-self: center;

    &__items {
      width: 40px;
      height: 40px;
      fill: var(--primary-color);
    }
  }
}
