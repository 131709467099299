@use '../base/mixins' as *;

.menu__button {
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  z-index: 950;
  cursor: pointer;

  @include breakpoint-up(medium) {
    top: 2rem;
    right: 3rem;
  }

  @include breakpoint-up(large) {
    top: 4rem;
    right: 5rem;
  }

  &:hover span,
  &:focus-within span,
  &:active span {
    background-color: var(--selection-color);
  }

  span {
    display: inline-block;
    background-color: var(--text-color);
    width: 2.5rem;
    height: 2px;
    align-self: flex-end;
    transition: all var(--duration-short) var(--basic-timing);

    &:nth-of-type(2) {
      width: 1.85rem;
    }
    &:nth-of-type(3) {
      width: 1.2rem;
    }
  }
  &.menu--open {
    transform: translate3d(-3px, -3px, 0);

    @include breakpoint-up(large) {
      top: 50%;
      left: 50%;
      transform: translate3d(365px, -267px, 0) !important;
    }

    span {
      &:nth-of-type(1) {
        // TODO: optimize these transforms?
        transform: rotate(-135deg) translate3d(-7px, -9.5px, 0);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        width: 2.5rem;
        transform: rotate(-45deg) translate3d(2px, -4.5px, 0);
      }
    }
    &:hover,
    &:focus-within,
    &:active {
      span {
        background-color: var(--selection-color);
      }
    }
  }
}
