@use '../base/mixins' as *;

.button-container {
  position: relative;
  margin: 0 -1rem;

  @include breakpoint-up(large) {
    margin-bottom: 2rem;
  }
}
button {
  display: inline-block;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  padding: 1.5rem 2.5rem;
  font-family: "Kanit", var(--fallback-font);
  font-weight: 800;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  line-height: 1;
  background-color: var(--primary-color);
  cursor: pointer;
  color: var(--bg-color);
}
.cta {
  clip-path: polygon(10% 0, 100% 0, 100% 80%, 90% 100%, 0 100%, 0 20%);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color var(--duration-short) var(--basic-timing),
    color var(--duration-medium) var(--basic-timing);

  &::before {
    content: "";
    position: absolute;
    top: -5%;
    left: -135%;
    height: 115%;
    width: 125%;
    background-color: var(--container-bg-color);
    transform: skew(-25deg);
    transition: transform var(--duration-short) var(--basic-timing);
    z-index: -1;
  }

  &:hover {
    color: var(--primary-color);

    &::before {
      transform: skew(-25deg) translateX(100%);
    }
  }
}

.cta:hover + .corners,
.cta:hover + .corners + .corners {
  border-color: var(--container-bg-color);
}

.corners {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: var(--bg-color);
  border: 0.25rem solid var(--primary-color);
  transition: border-color var(--duration-short) var(--basic-timing);

  &:before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 3rem;
    background-color: var(--bg-color);
  }

  &.top-block {
    top: 42px;
    left: 171px;
    border-left: 0;
    border-bottom: 0;

    &::before {
      top: 10px;
      left: 5px;
      @include breakpoint-up(small) {
        left: 10px;
      }
    }

    @include breakpoint-up(medium) {
      top: 12px;
    }
  }

  &.bottom-block {
    bottom: -8px;
    left: 12px;
    border-top: 0;
    border-right: 0;

    &::before {
      bottom: 10px;
      left: -5px;
    }

    @include breakpoint-up(xlarge) {
      bottom: -12px;
    }

    @include breakpoint-up(xxlarge) {
      bottom: -6px;
    }
  }
}

.project__button {
  position: relative;
  padding: 0.75rem 1.75rem;
  font-family: "Kanit", var(--fallback-font);
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-color);
  z-index: 1;

  &::before,
  &::after {
    position: absolute;
    content: "";
    left: 0%;
    height: 40%;
    width: 111%;
    border: 1px solid var(--primary-color);
    z-index: -2;
  }

  &::before {
    top: -10%;
    border-bottom: 0;
    transform: translateX(-5%) translateY(-10%);
  }
  &::after {
    bottom: -11%;
    border-top: 0;
    transform: translateX(-5%) translateY(10%);
  }

  &--hover {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    background: var(--primary-color);
    transform: scaleY(0);
    transition: transform var(--duration-short) var(--basic-timing);
    transition: transform 150ms var(--basic-timing);
    z-index: -10;
  }
  &:hover {
    color: var(--bg-color);

    .project__button--hover {
      transform: scaleY(1);
    }
  }
}
