@use '../base/mixins' as *;

.projects {
  &__card {
    height: 200px;
    margin-bottom: 2rem;
    position: relative;
    -webkit-clip-path: polygon(
      0% 0%,
      0% 15%,
      1% 18%,
      1% 85%,
      0% 88%,
      0% 100%,
      100% 100%,
      100% 88%,
      99% 85%,
      99% 18%,
      100% 15%,
      100% 0%
    );
    clip-path: polygon(
      0% 0%,
      0% 15%,
      1% 18%,
      1% 85%,
      0% 88%,
      0% 100%,
      100% 100%,
      100% 88%,
      99% 85%,
      99% 18%,
      100% 15%,
      100% 0%
    );
    background-color: var(--container-bg-color);
    place-self: center;
    display: flex;
    justify-content: center;
    padding: 0 2rem;

    // 500px - card container becomes flexible w/ size
    @media (min-width: 31.25em) {
      clip-path: polygon(
        0% 0%,
        0% 15%,
        1% 18%,
        1% 85%,
        0% 88%,
        0% 100%,
        100% 100%,
        100% 0%
      );
    }

    @include breakpoint-up(small) {
      clip-path: polygon(
        0% 0%,
        0% 15%,
        1% 18%,
        1% 85%,
        0% 88%,
        0% 100%,
        100% 100%,
        100% 88%,
        99% 85%,
        99% 18%,
        100% 15%,
        100% 0%
      );
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4%;
      background-color: var(--primary-color);
      box-shadow: 288px 0 0 var(--primary-color);

      @media (min-width: 23.4375em) {
        box-shadow: 302px 0 0 var(--primary-color);
      }

      @media (min-width: 25.6875em) {
        box-shadow: 337px 0 0 var(--primary-color);
      }

      @media (min-width: 31.25em) {
        box-shadow: none;
      }

      @include breakpoint-up(small) {
        box-shadow: 499px 0 0 var(--primary-color);
      }

      @include breakpoint-up(medium) {
        box-shadow: 432px 0 0 var(--primary-color);
      }
    }

    @include breakpoint-up(xsmall) {
      height: 250px;
    }

    @include breakpoint-up(small) {
      height: 265px;
    }

    @include breakpoint-up(medium) {
      height: 270px;
      width: 450px;
      margin-bottom: 0;
    }
  }
}
.em__card,
.hch__card {
  opacity: 0;
}
